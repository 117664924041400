<template>
  <div id="about-departments">
    <h1 class="departments-section-title text-dark_red pb-5 font-montserrat font-extrabold text-4xl
               text-center relative z-20 top-14 bg-grey rounded-bl-4xl sm:text-4-5xl sm:text-left sm:pl-40">
      {{$t('about.departments.title')}}
    </h1>
    <div class="departments relative">
      <!-- Desktop -->
      <Flicking ref="flickDesktop" :options="{align: 'prev', circular: false}"
                class="relative height-600px hidden sm:block"
                v-on:changed="onCardSet">
        <div v-for="(department,index) in $t('about.departments.departments')"
              class="flicking-panel relative rounded-4xl w-full h-full"
              :key="index">
          <div class="bg-black opacity-50 absolute top-0 left-0 w-full h-full object-cover z-0 rounded-4xl"/>
          <div class="relative z-10 top-10 px-10 sm:px-18 xxl:px-28">
            <div class="flex justify-center mt-4">
              <p class="text-white bg-dark_red inline-block font-montserrat font-bold text-3xl px-10 py-2
                          rounded-b-3xl sm:py-10 acronym relative z-20">
                {{department.acronym}}
              </p>
            </div>
            <div class="flex justify-around items-start">
              <p class="text-white font-roboto text-left sm:text-xl  xxl:text-2xl sm:w-80 xxl:w-96 inline-block
                        flex-shrink-0">
                {{department.descriptionLeft}}
              </p>
              <img :src="department.backgroundImage"
                   alt="Department Photo"
                   class="department-photo inline-block object-cover sm:mx-8 xxl:mx-14"
              />
              <p class="text-white font-roboto text-left sm:text-xl xxl:text-2xl sm:w-80 xxl:w-96 inline-block
                        flex-shrink-0">
                {{department.descriptionRight}}
              </p>
            </div>
          </div>
        </div>
      </Flicking>
      <!-- Mobile -->
      <Flicking ref="flickMobile" :options="{align: 'prev', circular: false}"
                class="relative height-600px sm:hidden"
                v-on:changed="onCardSet">
        <div v-for="(department,index) in $t('about.departments.departments')"
              class="card-background-image flicking-panel relative rounded-4xl w-full h-full"
              :style="{ backgroundImage: `url(${department.backgroundImage})` }"
              :key="index">
          <div class="bg-black opacity-50 absolute top-0 left-0 w-full h-full object-cover z-0 rounded-4xl"/>
          <div class="relative z-10 top-10 px-10">
            <div class="flex justify-center mt-4">
              <p class="text-white bg-dark_red inline-block font-montserrat font-bold text-3xl px-10 py-2
                          rounded-b-3xl acronym relative z-20">
                {{department.acronym}}
              </p>
            </div>
            <p class="text-white mt-10 font-roboto text-center text-sm">
              {{department.description}}
            </p>
          </div>
        </div>
      </Flicking>
      <div class="absolute arrow-navigator z-20 flex items-center">
        <div class="w-8 h-8 up-arrow transform -rotate-90" @click="handleArrowClick(-1)"/>
        <div v-for="(_,index) in $t('about.departments.departments')" :key="index"
              class="w-4 h-4 rounded-full bg-dark_red mx-3"
              :class="{'border-2 border-pink border-solid box-content': index == currentIndex}"></div>
        <div class="w-8 h-8 down-arrow transform -rotate-90" @click="handleArrowClick(1)"/>
      </div>
    </div>
    <div class="departments-trainings pt-5 mb-20 relative ml-28 sm:ml-64" id="scroll-anchor">
      <Flicking :options="{ align: 'prev', circular: false, bound: true }"
                class="relative height-420">
        <div v-for="(training,index) in $t('about.departments.departmentsTrainings')"
              class="flicking-panel-trainings bg-cover h-96 w-52 relative mr-10 rounded-2xl sm:w-96
                     "
              :style="{ backgroundImage: `url(${training.backgroundImage})` }"
              :key="index">
          <div class="bg-black opacity-70 absolute top-0 w-52 left-0 h-full object-cover z-0 rounded-2xl sm:w-96"/>
          <div class="relative z-10 px-3 py-4 sm:text-left">
            <p class="text-white font-montserrat text-2xl ml-3 mb-2 sm:px-10 sm:pt-8 sm:mb-4
                      sm:transform sm:-translate-x-3">
              {{training.name}}
            </p>
            <p class="text-white font-roboto font-light text-center sm:px-8 sm:mb-8 description sm:text-left">
              {{training.description}}
            </p>
          </div>
          <!-- <div class="training-arrow w-11 h-11 border-8 border-solid border-black rounded-full absolute"
          >
          </div> -->
        </div>
      </Flicking>
      <div class="trainings-section-title absolute top-0 -left-10 h-full z-20 sm:-left-14">
        <p class="bg-dark_red text-vertical font-montserrat font-medium text-3xl text-white rounded-b-xl inline-block
                  px-1 h-full text-center relative z-30">
          {{$t('about.departments.departmentsTrainingsTitle')}}
        </p>
      </div>
    </div>
    <DepartmentsTrainings
      v-if="$store.state.globalModule.trainingModalIsOpen"
      :departmentTrainingIndex="currentDepartmentTrainingIndex"
      class="absolute top-0 left-0 z-50"
      @closeModal="closeDepTrainingModal()"
    />
  </div>
</template>

<script>
import DepartmentsTrainings from './AboutDepartmentsTrainings.vue';

export default {
  components: {
    DepartmentsTrainings,
  },
  data() {
    return {
      currentIndex: 0,
      currentDepartmentTrainingIndex: 0,
    };
  },
  methods: {
    handleArrowClick(direction) {
      this.currentIndex += direction;
      if (this.currentIndex === this.$t('about.departments.departments').length) {
        this.currentIndex = 0;
      } else if (this.currentIndex === -1) {
        this.currentIndex = this.$t('about.departments.departments').length - 1;
      }
      this.$refs.flickDesktop.moveTo(this.currentIndex);
      this.$refs.flickMobile.moveTo(this.currentIndex);
    },
    onCardSet(event) {
      this.currentIndex = event.index;
    },
    openDepTrainingModal(index) {
      this.currentDepartmentTrainingIndex = index;
      this.$store.state.globalModule.trainingModalIsOpen = true;
      window.scrollTo(0, 0);
    },
    closeDepTrainingModal() {
      this.$store.state.globalModule.trainingModalIsOpen = false;
      const anchor = document.getElementById("scroll-anchor");
      //this needs to be here because it takes a bit for the overflow property
      //on #app to be removed and the scroll to be able to happen
      setTimeout(() => {
        anchor.scrollIntoView(false);
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.height-600px {
  height: 600px
}
.height-420 {
  height: 420px;
}
.arrow-navigator {
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}
.acronym {
  @media screen and (min-width: 480px) {
    padding: 30px 40px 20px 40px!important;
  }
}
.up-arrow {
  background-image: url('../../assets/icons/up-arrow.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
.down-arrow {
  background-image: url('../../assets/icons/down-arrow.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.up-arrow, .down-arrow {
  cursor: pointer;
}

.training-arrow {
  background-color: $black;
  background-image: url('../../assets/icons/down-arrow-pink.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  bottom: 0;
  left: 40%;
  transform: translateY(50%);
  z-index: 30;
  @media screen and (min-width: 480px) {
    &:hover {
      cursor: pointer;
    }

    &::before, &::after {
      content: "";
      position: absolute;
      width: 100px;
      height: 50px;
      top: -50px;
      left: -25px;
    }
  }
}

.trainings-section-title {
  &::before {
    content: "";
    position: absolute;
    left: -90px;
    height: 100%;
    width: 100px;
    z-index: 0;
    background-color: $grey;
    @media screen and (min-width: 480px) {
      width: 5000px;
      left: -4990px;
    }
  }

  & > p {
    @media screen and (min-width: 480px) {
      padding: 0 10px!important;
    }
  }
}
.text-vertical {
  writing-mode: vertical-lr;
  text-orientation: upright;
  box-shadow: 5px 0px 5px -5px rgba(250,245,245,1);
}

.flicking-panel-trainings {
  box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.75);
}

.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  @media screen and (min-width: 480px) {
    -webkit-line-clamp: 10;
  }
}

.card-background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.department-photo {
  height: 544px;
  position: relative;
  top: -86px;
  z-index: 0;
  flex-grow: 0;
  max-width: 40%;
  @media screen and (min-width: 1600px) {
    max-width: 100%;
  }
}
</style>
