<!-- eslint-disable max-len -->
<template>
  <div id="departamet-trainings-modal" class="bg-pink w-full">
    <div :style="{ backgroundImage: `url(${$t('about.departments.departmentsTrainings[' + departmentTrainingIndex + '].backgroundImage')})` }" class="w-screen bg-cover parallax"/>
    <img src="../../assets/icons/back-arrow.svg" alt="Back Arrow" class="absolute top-0 left-0 w-8 h-8 ml-5 mt-5 cursor-pointer"
      @click="$emit('closeModal');">
    <div class="dep-training-data px-10 rounded-t-4xl relative bottom-10 bg-pink">
      <p class="absolute -top-14 font-montserrat text-5xl font-medium text-white sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-top-96">
        {{ $t('about.departments.departmentsTrainings[' + departmentTrainingIndex + '].name') }}
      </p>
      <div
        id="content"
        class="pt-5 font-roboto text-base font-normal text-black sm:max-w-screen-lg sm:mx-auto sm:pt-20"
        v-html="$t('about.departments.departmentsTrainings[' + departmentTrainingIndex + '].content')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ['departmentTrainingIndex'],
  created() {
    console.log(this.departmentTrainingIndex);
  },
};
</script>

<style lang="scss" scoped>
.parallax {
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 90vh;
  &::after {
    content: "";
    background: $dark_grey;
    position: absolute;
    inset: 0;
    opacity: 0.7;
    z-index: 0;
  }
}

.dep-training-data {
  box-shadow: 0px -6px 10px -5px rgba(0,0,0,0.75);
}

#content {
  @media screen and (min-width: 480px) {
    padding-top: 80px!important;
  }
}
</style>
