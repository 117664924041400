<template>
  <div id="about-page">
    <AboutHero/>
    <AboutDepartments/>
    <AboutInternational />
    <AboutBoard/>
  </div>
</template>

<script>
import AboutBoard from '../components/about/AboutBoard.vue';
import AboutHero from "../components/about/AboutHero.vue";
import AboutDepartments from "../components/about/AboutDepartments.vue";
import AboutInternational from '../components/about/AboutInternational.vue';

export default {
  components: {
    AboutHero,
    AboutBoard,
    AboutInternational,
    AboutDepartments,
  },
  data() {
    return {
      //comentariu ca sa nu dea ESLint warning
    };
  },
};
</script>

<style lang="scss" scoped>
//comentariu ca sa nu dea ESLint warning
</style>
