<template>
  <div id="about-international">
    <h1 class="mb-8 text-5xl font-extrabold sm:text-4-5xl text-dark_red">{{ $t("about.international.title") }}</h1>
    <p class="w-9/12 m-auto mt-6 ">{{ $t("about.international.paragraph") }}</p>
    <div id="events-banner" class="w-4/5 py-4 mb-10 mt-14 sm:w-1/4">
      <h2 id="banner-title" class="sm:-my-2 sm:pr-14 sm:text-2xl">{{ $t("about.international.banner") }}</h2>
    </div>
    <div
      :class="{ row: true, reverse: index !== 1 }"
      class="relative event sm:text-base sm:px-16 sm:mt-6"
      v-for="(event, index) in $t('about.international.events')"
      :key="index"
    >
      <div v-if="index % 2 == 1">
        <img
          class="plane-left sm:hidden"
          src="../../assets/images/plane-left.svg"
          alt=""
        />
        <img
          class="plane-left hidden sm:block"
          src="../../assets/icons/plane-left-desktop.svg"
          alt=""
        />
        <div class="sm:translate-x-24 sm:transform sm:w-2/3">
          <h2 class="event-name-right sm:font-bold">{{ event.name }}</h2>
          <p class="event-description-right" v-html="event.description"></p>
        </div>
      </div>
      <div v-if="index % 2 !== 1" class="mt-16 sm:flex sm:justify-end">
        <img
          class="plane-right sm:hidden"
          src="../../assets/images/plane-right.svg"
          alt=""
        />
        <img
          class="plane-right hidden sm:block"
          src="../../assets/icons/plane-right-desktop.svg"
          alt=""
        />
        <div class="sm:-translate-x-24 sm:transform sm:w-2/3">
          <h2 class="event-name-left sm:font-bold">{{ event.name }}</h2>
          <p class="event-description-left" v-html="event.description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
#about-international {
  width: 100%;
  height: 100%;
  background-color: $grey;
}

h1 {
  color: $dark_red;
  font-family: "Montserrat";
  text-align: center;
}

p {
  color: $black;
  font-family: "Roboto";
  text-align: center;
}

#events-banner {
  background-color: $dark_red;
  border-radius: 0 16px 16px 0;
  text-align: right;
}

#banner-title {
  color: $white;
  font-family: "Montserrat";
  font-size: 20px;
  margin-right: 12px;
}

.event {
  margin-left: 2em;
  margin-right: 2em;
}

.row {
  display: flex;
  align-items: center;
  flex-basis: 100%;

  &.reverse {
    flex-direction: row-reverse;
  }
}

.plane-right {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(36px);
  @media screen and (min-width: 480px) {
    transform: translateX(56px) translateY(-32px);
  }
}

.event-name-right {
  color: $black;
  font-family: "Montserrat";
  font-size: 24px;
  text-align: left;
  margin: 30px;
  margin-top: 10px;
  line-height: 1.2em;
}

.event-description-right {
  color: $black;
  font-family: "Roboto";
  text-align: left;
  margin: 30px;
}

.plane-left {
  margin-left: -36px;
  @media screen and (min-width: 480px) {
    margin-left: 0;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-56px) translateY(-92px);
  }
}

.event-name-left {
  color: $black;
  font-family: "Montserrat";
  font-size: 24px;
  text-align: right;
  margin: 30px;
  margin-top: 10px;
  line-height: 1.2em;
}

.event-description-left {
  color: $black;
  font-family: "Roboto";
  text-align: right;
  margin: 30px;
}
</style>
