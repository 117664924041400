<template>
  <div id="scroll-down-arrow"
       class="down-arrow z-20 h-10 w-10 absolute bottom-4 left-1/2 cursor-pointer -translate-x-5 transform
              hidden sm:block"
       :class="{'down-arrow-red': variant == 'red'}"
       @click="scrollTo()"/>
</template>

<script>
export default {
  props: [
    'variant',
  ],
  methods: {
    scrollTo() {
      window.scrollTo({
        top: window.innerHeight,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.down-arrow {
  background-image: url('../../../assets/icons/down-arrow-pink.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  &-red {
    background-image: url('../../../assets/icons/down-arrow-red.svg');
  }
}
</style>
