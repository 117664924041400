<template>
  <div id="about-board">
    <h1
      class="mt-10 text-4xl font-extrabold text-center text-darker_red font-montserrat mb-28
      sm:text-left sm:text-white sm:bg-darker_red sm:inline-block sm:pl-64 sm:pr-16 sm:py-2
      sm:rounded-tr-xl sm:rounded-br-xl sm:font-normal sm:text-2xl"
    >
      Board
    </h1>
    <div class="board-wrapper w-2/3 mx-auto pb-9 sm:flex-wrap sm:flex sm:justify-center sm:relative sm:z-20">
      <div
      class="board-member flex items-center w-4/5 max-w-xs mb-16 sm:relative"
      v-for="(member, index) in $t('about.aboutBoard.Board')"
      :key="index"
      >
        <div :class = "{ first : member.first, last: member.last}"
            class="z-10 flex-shrink-0 board-photo sm:relative sm:z-20">
          <img
            class= "object-cover w-32 h-32 border rounded-xl border-darker_red"
            :src="member.url"
            :alt="member.position"
          />
        </div>
        <div class="ml-3 sm:relative sm:z-10">
          <h4 class="text-2xl font-medium font-montserrat text-darker_red">
            {{ member.forename }}
          </h4>
          <h4 class="text-2xl font-medium font-montserrat text-darker_red">
            {{ member.surname }}
          </h4>
          <h5 class="text-sm text-black font-roboto sm:text-white sm:font-extralight position-text sm:relative sm:z-20
              sm:transform sm:translate-y-0.25">
            {{ member.position }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.board-photo {
  position: relative;
  &::after {
    content: "";
    background-color: $black;
    position: absolute;
    height: 64px;
    width: 40%;
    bottom: -64px;
    left: 30%;
    @media screen and(min-width: 480px) {
      content: none;
    }
  }
}
.first {
  &::before {
    content: "";
    background-color: $black;
    position: absolute;
    height: 64px;
    width: 40%;
    top: -64px;
    left: 30%;
    border-radius: 15px 15px 0 0;
    @media screen and(min-width: 480px) {
      content: none;
    }
  }
}
.last{
  &::after {
    bottom: -100px;
    height: 100px;
    @media screen and (min-width: 480px) {
      height: 32px;
    }
  }
}
.board-member:first-child  {
  @media screen and (min-width:480px) {
    max-width: none;
    width: 100%;
    justify-content: center;
    transform: translateX(-47px);
  }
}
.board-wrapper{
  max-width: 1220px;
  @media screen and(min-width: 480px) {
    &::after{
      content: "";
      position: absolute;
      z-index: 20;
      box-shadow: -12px 0px 8px -8px rgba(0,0,0,0.5);
      height: 90%;
      width: 100vw;
      top: 0;
      right: -95vw;
      background-color: $pink;
    }
    &::before{
      content: "";
      position: absolute;
      z-index: 20;
      box-shadow: 12px 0px 8px -8px rgba(0,0,0,0.5);
      height: 90%;
      width: 100vw;
      top: 0;
      left: -100vw;
      background-color: $pink;
    }
  }
}

.board-member:nth-child(1) h5 {
  &::after {
    @media screen and(min-width: 480px) {
      content: "";
      position: absolute;
      z-index: -1;
      width: 200vw;
      height: 24px;
      bottom: -2px;
      left: -100vw;
      background-color: $black;
    }
  }
}

.board-member:nth-child(2) h5, .board-member:nth-child(5) h5{
  &::after {
    @media screen and(min-width: 480px) {
      content: "";
      position: absolute;
      z-index: -1;
      width: 200vw;
      height: 24px;
      bottom: -2px;
      left: -100vw;
      background-color: $black;
    }
  }
}

.board-member:nth-child(1) > .board-photo > img {
  object-position: center ;
}

.board-member:nth-child(6) > .board-photo > img {
  object-position: center ;
}

.board-member:nth-child(7) > .board-photo > img {
  object-position: center ;
}
</style>
