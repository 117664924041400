<template>
  <div id="about-hero" class="relative">
    <div class="image-container w-screen relative flex justify-center items-center">
      <div class="hero-image absolute w-full h-full top-0 left-0"></div>
      <div class="red-overlay w-full h-full bg-dark_red opacity-30 absolute top-0 left-0 z-10 sm:opacity-30"/>
      <div class="flex z-20 flex-wrap w-2/3 justify-center gap-y-16">
        <img src="../../assets/images/Logo.png" alt="EESTEC Bucuresti Logo" class="w-36 z-20 sm:hidden">
        <p class="text-center text-white font-roboto sm:text-xl sm:w-2/3"> {{$t("about.hero.description")}} </p>
      </div>
    </div>
    <div class="sm:hidden">
      <div class="button-container flex justify-center transform -translate-y-8 relative z-20">
        <div class="button rounded-xl border-2 border-dark_red w-16 h-16 flex justify-center items-center
                    shadow-lg sm:translate-y-0 mx-2"
             v-for="(item, index) in $t('about.hero.vmv')" :key="parseInt(index)"
             @click="handleVMVClick(index)"
             :class="{'bg-dark_red': index == currentIndex, 'bg-white': index != currentIndex}">
            <p class="font-bold text-dark_red text-4xl font-montserrat text-shadow"
               :class="{'text-white': index == currentIndex, 'text-dark_red': index != currentIndex}">
                 {{item.acronym}}
            </p>
        </div>
      </div>
      <Flicking ref="flick"
                :options="{ renderOnlyVisible: false, preventClickOnDrag: true, autoResize: false,
                            bound:false, align: 'center', resizeOnContentsReady: true, needPanelThreshold: 0,
                            panels: 3, panelsPerView: 1, moveType: 'snap', circular: true}"
                 v-on:changed="onCardSet">
        <div class="m-auto flex flex-wrap justify-center gap-y-8"
            v-for="(item, index) in $t('about.hero.vmv')" :key="index">
          <h2 class="text-dark_red font-bold text-4xl font-montserrat"> {{$t(item.title)}} </h2>
          <p class="text-center font-roboto px-20" v-html="$t(item.description)"></p>
        </div>
      </Flicking>
    </div>
    <div class="hidden sm:flex justify-center gap-x-14 transform -translate-y-24 relative z-20">
      <div v-for="(item, index) in $t('about.hero.vmv')" :key="index"
           class="box-shadow bg-pink rounded-2xl flex flex-col items-center px-4 py-14 w-96 hover:bg-dark_red">
        <p class="font-montserrat font-extrabold text-4-5xl text-darker_red mb-10">{{item.title}}</p>
        <p class="text-center font-roboto" v-html="$t(item.description)"></p>
      </div>
    </div>
    <ScrollDownArrowVue variant="red"/>
  </div>
</template>

<script>

import ScrollDownArrowVue from '../global/Desktop/ScrollDownArrow.vue';

export default {
  components: {
    ScrollDownArrowVue,
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    handleVMVClick(index) {
      this.currentIndex = index;
      this.$refs.flick.moveTo(this.currentIndex);
    },
    onCardSet(event) {
      this.currentIndex = event.index;
    },
  },
};
</script>

<style lang="scss" scoped>
.hero-image {
  background-image: url("https://i.imgur.com/Tf2pMAo.jpg");
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-container {
  height: 105vh;
  @media screen and (min-width: 480px) {
    height: 70vh;
    max-height: 800px;
  }
}
.text-shadow {
  text-shadow: 0px 3px 6px rgba(0,0,0,0.3)
}

.box-shadow {
  box-shadow: 0px 0px 5px -1px rgba(51,51,51,1);
  transition: background-color 0.2s ease-in;
  & p {
    transition: color 0.2s ease-in;
  }
  &:hover p{
    color: $white;
  }
}
</style>
